import { makeBetterEnum } from './betterEnum';

export const BiChartKind = makeBetterEnum(
  'BarHorizontal',
  'BarVertical',
  'TimeSeries',
  'Pie',
  'Treemap',
  'Meter',
  'BigNumber',
  'Pareto'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BiChartKind = (typeof BiChartKind)[keyof typeof BiChartKind];

export const BiFilterOperator = makeBetterEnum(
  'In',
  'NotIn',
  'Empty',
  'NotEmpty',
  'Equal',
  'NotEqual',
  'LessThan',
  'LessThanOrEqual',
  'GreaterThan',
  'GreaterThanOrEqual',
  'Contains', // case-insensitive; string contains single string
  'DoesNotContain' // case-insensitive; string does not contain single string
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BiFilterOperator =
  (typeof BiFilterOperator)[keyof typeof BiFilterOperator];

export const BiQueryMode = makeBetterEnum(
  'Activity',
  'Snapshot',
  'FinanceFootprintSnapshot',
  'Finance',
  'Lineage'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BiQueryMode = (typeof BiQueryMode)[keyof typeof BiQueryMode];
